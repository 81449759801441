import React, { useState } from 'react';
import { Modal } from '../../../../components'; // Adjust the import paths as per your project structure
import css from './FeatureSelector.module.css'; // Add relevant styles
import { Field } from 'react-final-form';

const FeatureSelector = ({ form, values }) => {
  // Use the array of feature strings directly
  const [features, setFeatures] = useState(values.filterHotelCustom || []);
  const [featureLabel, setFeatureLabel] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState('');

  const updateFormWithFeatures = newFeatures => {
    form.change('filterHotelCustom', newFeatures);
    form.change('hasFeatures', newFeatures.length > 0);
  };

  const handleAddFeature = () => {
    if (featureLabel) {
      const updatedFeatures = [...features, featureLabel]; // Add feature as a string
      setFeatures(updatedFeatures);
      updateFormWithFeatures(updatedFeatures);
      setFeatureLabel('');
      setIsModalOpen(false);
      setValidationError('');
    } else {
      setValidationError('Feature label cannot be empty.');
    }
  };

  const handleRemoveFeature = indexToRemove => {
    const updatedFeatures = features.filter((_, index) => index !== indexToRemove);
    setFeatures(updatedFeatures);
    updateFormWithFeatures(updatedFeatures);
    if (updatedFeatures.length === 0) {
      setValidationError('You need to add at least one feature.');
    }
  };

  const openModal = () => {
    setValidationError('');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={css.featureSelectorContainer}>
      <div className={css.titleWrapper}>
        <label>Features & Attractions</label>
        <button type="button" className={css.openModalButton} onClick={openModal}>
          Add Feature
        </button>
      </div>

      {validationError && <div className={css.validationMessage}>{validationError}</div>}

      {/* Hidden field to ensure validation */}
      <Field name="hasFeatures" component="input" type="hidden" />

      <div className={css.selectedFeaturesWrapper}>
        {features.map((feature, index) => (
          <div key={index} className={css.featurePill}>
            <span>{feature}</span> {/* Display feature as a string */}
            <button
              type="button"
              className={css.removeButton}
              onClick={() => handleRemoveFeature(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <Modal
        id="featureSelectorModal"
        isOpen={isModalOpen}
        onClose={closeModal}
        onManageDisableScrolling={() => {}}
        usePortal
      >
        <div className={css.modalContent}>
          <p>Add a New Feature</p>
          <input
            type="text"
            value={featureLabel}
            onChange={e => setFeatureLabel(e.target.value)}
            placeholder="Feature label"
          />
          {validationError && <div className={css.validationMessage}>{validationError}</div>}

          <div className={css.modalActions}>
            <button type="button" className={css.addButton} onClick={handleAddFeature}>
              Add Feature
            </button>
            <button type="button" className={css.cancelButton} onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FeatureSelector;
