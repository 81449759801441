import React, { useEffect } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import appSettings from '../../../../config/settings';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldSelect,
  FieldCheckbox,
} from '../../../../components';

// Import modules from this directory
import css from './EditListingPricingForm.module.css';
import { supportedCountries } from '../../../../config/configStripe';
import { countryNames } from '../../../../config/configCustomCurrency';

const { Money } = sdkTypes;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

export const EditListingPricingFormComponent = props => {
  // Use state to track the selected currency
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          formId,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          marketplaceCurrency,
          unitType,
          listingMinimumPriceSubUnits,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          /* custom */
          values,
          onCurrencyChange,
          selectedCurrency,
          form,
        } = formRenderProps;

        // Update the selected currency when it changes
        useEffect(() => {
          const newCountryCode = values.countryCode || 'IE';
          if (newCountryCode !== selectedCurrency.countryCode) {
            const newCurrencyData = supportedCountries.find(
              country => country.code === newCountryCode
            );
            let hasCurrencyChanged = undefined;
            values['currency'] = newCurrencyData.currency;
            if (
              (values.price && newCurrencyData.currency !== values.price.currency) ||
              newCurrencyData.currency !== selectedCurrency.currency
            ) {
              hasCurrencyChanged = true;
              const newPrice = null;
              form.change('price', newPrice);
            }
            onCurrencyChange({
              currency: newCurrencyData.currency,
              countryCode: newCountryCode,
              currencyChanged: hasCurrencyChanged,
            });
          }
        }, [values.countryCode, selectedCurrency.countryCode]);

        const priceValidators = getPriceValidators(
          listingMinimumPriceSubUnits,
          selectedCurrency.currency,
          intl
        );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        // Use the selected currency to get the appropriate currency configuration
        const currencyConfig = appSettings.getCurrencyFormatting(selectedCurrency.currency);

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <FieldSelect
              id="countryCode"
              name="countryCode"
              className={css.currency}
              label={`Country:`}
              autoFocus={autoFocus}
            >
              <option value="">Pick something...</option>
              {supportedCountries.map(country => (
                <option key={country.code} value={country.code}>
                  {countryNames[country.code]}
                </option>
              ))}
            </FieldSelect>
            <FieldCurrencyInput
              key={selectedCurrency.currency}
              id={`${formId}price`}
              name="price"
              className={css.input}
              /* autoFocus={autoFocus} */
              label={intl.formatMessage(
                { id: 'EditListingPricingForm.pricePerProduct' },
                { unitType }
              )}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingForm.priceInputPlaceholder',
              })}
              currencyConfig={currencyConfig}
              validate={priceValidators}
            />

            <div className={css.priceFlexibility}>
              <label>
                <FormattedMessage id="EditListingDetailsForm.priceFlexibility.label" />
              </label>

              <FieldCheckbox
                id={`${formId}priceFlexibility`}
                name="priceFlexibility"
                label={intl.formatMessage({
                  id: 'EditListingDetailsForm.negotiatePrice.label',
                })}
                value="true"
              />
              <small>
                <FormattedMessage id="EditListingDetailsForm.priceFlexibility.info" />
              </small>
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};
EditListingPricingFormComponent.defaultProps = {
  fetchErrors: null,
  listingMinimumPriceSubUnits: 0,
  formId: 'EditListingPricingForm',
};

EditListingPricingFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  marketplaceCurrency: string.isRequired,
  unitType: string.isRequired,
  listingMinimumPriceSubUnits: number,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
